var render = function render() {
    var _vm = this, _c = _vm._self._c;
    return _c("v-snackbar", {
        attrs: { id: "snackbar", timeout: _vm.timeout },
        model: {
            value: _vm.snackbarIsOpen,
            callback: function ($$v) {
                _vm.snackbarIsOpen = $$v;
            },
            expression: "snackbarIsOpen",
        },
    }, [
        _vm._v("\n  " +
            _vm._s(_vm.translate ? _vm.$t(_vm.snackbarMessage) : _vm.snackbarMessage) +
            "\n  "),
        _c("v-btn", {
            attrs: { flat: "", color: _vm.snackbarColor },
            nativeOn: {
                click: function ($event) {
                    return _vm.hideSnackbar();
                },
            },
        }, [_vm._v(_vm._s(_vm.$t("close")))]),
    ], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
