export const DatePeriods = {
    DAYS: 'days',
    MONTHS: 'months',
};
export const DateOperators = {
    BEFORE: 'before',
    AFTER: 'after',
};
export const Filter = {
    expirein: 'expirein',
    comingupin: 'comingupin',
    withactivecovenant: 'withactivecovenant',
    arrivelastmonth: 'arrivelastmonth',
    arrivethismonth: 'arrivethismonth',
    daterange: 'daterange',
    numeric: 'numeric',
    age: 'age',
    boolean: 'boolean',
    emptystring: 'emptystring',
    multiselect: 'multiselect',
    schedule: 'schedule',
    infosession: 'infosession',
    eventDate: 'eventDate',
    aupairsFulfilling42DaysRule: 'aupairsFulfilling42DaysRule',
    aupairsWithSentEmail: 'aupairsWithSentEmail',
    aupairsWithUncheckedBoxes: 'aupairsWithUncheckedBoxes',
};
